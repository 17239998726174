import gql from "graphql-tag";

export const SHIFT_PAGINATE = gql`
  query FilterWithPaginateWorkplans($criteria: WorkplanFilterCriteria!, $pagination: Pagination!) {
    filterWithPaginateWorkplans(criteria: $criteria, pagination: $pagination) {
      pageInfo {
        totalElements
        totalPages
        pageNumber
      }
      content {
        id
        date
        countAsOvertime
        assignedShifts {
          id
          name
          beginTime
          endTime
          shiftType
          totalWorkingTimeInMinutes
          expectedWorkingTimeInMinutes
        }
      }
    }
  }
`;

export const WORKPLANS_PAGINATE = gql`
  query FilterWithPaginateWorkplans($criteria: WorkplanFilterCriteria!, $pagination: Pagination!) {
    filterWithPaginateWorkplans(criteria: $criteria, pagination: $pagination) {
      pageInfo {
        totalElements
        totalPages
        pageNumber
      }
      content {
        id
        date
        countAsOvertime
        overtimeDisabled
        missingTimeDisabled
        assignedShifts {
          id
          expectedWorkingTimeInMinutes
          shiftType
        }
        leaveRequest {
          id
          leaveType {
            color
            icon
            name
          }
        }
        timesheets {
          id
          actualWorkingTimeInMinutes
          clockInTimestamp
          clockOutTimestamp
          shift {
            beginTime
            endTime
            shiftType
            totalWorkingTimeInMinutes
            expectedWorkingTimeInMinutes
          }
          totalOvertimeInMinutes
          totalMissingTimeInMinutes
        }
        calculatedTimesheet {
          id
          actualWorkingTimeInMinutes
          clockInTimestamp
          clockOutTimestamp
          latelyClockInTimeInMinutes
          earlyClockOutTimeInMinutes
          clockInIdentityType
          clockOutIdentityType
          totalOvertimeInMinutes
          totalMissingTimeInMinutes
          firstClockIn {
            id
          }
          lastClockOut {
            id
          }
          clockInTerminal {
            name
          }
          clockInAccessTerminal {
            name
          }
          clockOutTerminal {
            name
          }
          clockOutAccessTerminal {
            name
          }
          shift {
            id
            name
            color
            beginTime
            endTime
            shiftType
            lengthInMinutes
            expectedWorkingTimeInMinutes
            totalWorkingTimeInMinutes
            totalBreakTimeInMinutes
            latelyClockInTolerance
            earlyClockOutTolerance
            maxClockOutOvertimeInMinutes
            maxClockInOvertimeInMinutes
          }
        }
      }
    }
  }
`;

export const OVERTIME_APPROVAL = gql`
  query employee($id: ID!) {
    employee(id: $id) {
      pendingApprovalOvertimeRequests {
        id
        overtime {
          requestStatus
          requestedDateTime
          workplan {
            date
          }
          overtimeInMinutes
          reason
          description
          employee {
            id
            firstName
            lastName
            profile {
              imageUrl
            }
            employment {
              branch {
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const PendingOvertimeRequests = gql`
  query PendingOvertimeRequests($id: ID!) {
    employee(id: $id) {
      pendingApprovalOvertimeRequests {
        id
        approver {
          id
        }
        overtime {
          requestStatus
          requestedDateTime
          workplan {
            allLeaveRequest {
              id
              leaveRequestStatus
              leaveType {
                color
                name
              }
              leaveRequestDays {
                duration
                date
                leaveRequestDayType
              }
            }
            date
            assignedShifts {
              name
              beginTime
              endTime
              shiftType
            }
            calculatedTimesheet {
              shift {
                id
                name
                color
                beginTime
                endTime
                shiftType
                lengthInMinutes
                expectedWorkingTimeInMinutes
                totalWorkingTimeInMinutes
                totalBreakTimeInMinutes
                latelyClockInTolerance
                earlyClockOutTolerance
                maxClockOutOvertimeInMinutes
                maxClockInOvertimeInMinutes
                minClockOutOvertimeInMinutes
                minClockInOvertimeInMinutes
                maxOvertimeInMinutes
                minOvertimeInMinutes
                midnightTolerance
                totalBreakTimeInMinutesForWeb
              }
              clockInTimestamp
              clockInIdentityType
              clockInTerminal {
                name
              }
              latelyClockInTimeInMinutes
              clockOutTimestamp
              clockOutIdentityType
              clockOutTerminal {
                name
              }
              earlyClockOutTimeInMinutes
              totalOvertimeInMinutes
              actualWorkingTimeInMinutes
              totalMissingTimeInMinutes
            }
          }
          overtimeInMinutes
          period
          reason
          description
          employee {
            id
            firstName
            lastName
            profile {
              imageUrl
            }
            employment {
              branch {
                name
              }
              unit {
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const AllPendingOvertimeRequests = gql`
  query AllPendingOvertimeRequests($id: ID!) {
    employee(id: $id) {
      allPendingApprovalOvertimeRequests {
        id
        approver {
          id
        }
        overtime {
          requestStatus
          requestedDateTime
          workplan {
            allLeaveRequest {
              id
              leaveRequestStatus
              leaveType {
                color
                name
              }
              leaveRequestDays {
                duration
                date
                leaveRequestDayType
              }
            }
            date
            assignedShifts {
              name
              beginTime
              endTime
              shiftType
            }
            calculatedTimesheet {
              shift {
                id
                name
                color
                beginTime
                endTime
                shiftType
                lengthInMinutes
                expectedWorkingTimeInMinutes
                totalWorkingTimeInMinutes
                totalBreakTimeInMinutes
                latelyClockInTolerance
                earlyClockOutTolerance
                maxClockOutOvertimeInMinutes
                maxClockInOvertimeInMinutes
                minClockOutOvertimeInMinutes
                minClockInOvertimeInMinutes
                maxOvertimeInMinutes
                minOvertimeInMinutes
                midnightTolerance
                totalBreakTimeInMinutesForWeb
              }
              clockInTimestamp
              clockInIdentityType
              clockInTerminal {
                name
              }
              latelyClockInTimeInMinutes
              clockOutTimestamp
              clockOutIdentityType
              clockOutTerminal {
                name
              }
              earlyClockOutTimeInMinutes
              totalOvertimeInMinutes
              actualWorkingTimeInMinutes
              totalMissingTimeInMinutes
            }
          }
          overtimeInMinutes
          period
          reason
          description
          employee {
            id
            firstName
            lastName
            profile {
              imageUrl
            }
            employment {
              branch {
                name
              }
              unit {
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const EMPLOYEE_MANAGERS = gql`
  query employee($id: ID!) {
    employee(id: $id) {
      timeSettings {
        overtimeApprover
        shiftApprover
      }
    }
  }
`;

export const FILTER_WORKPLANS = gql`
  query filterWorkplans($criteria: WorkplanFilterCriteria!) {
    filterWorkplans(criteria: $criteria) {
      id
      date
      countAsOvertime
      overtimeDisabled
      missingTimeDisabled
      assignedShifts {
        id
        expectedWorkingTimeInMinutes
      }
      leaveRequest {
        id
        leaveType {
          color
          icon
          name
        }
      }
      timesheets {
        id
        actualWorkingTimeInMinutes
        clockInTimestamp
        clockOutTimestamp
        shift {
          beginTime
          endTime
          shiftType
          totalWorkingTimeInMinutes
          expectedWorkingTimeInMinutes
        }
        totalOvertimeInMinutes
        totalMissingTimeInMinutes
      }
      calculatedTimesheet {
        id
        actualWorkingTimeInMinutes
        clockInTimestamp
        clockOutTimestamp
        latelyClockInTimeInMinutes
        earlyClockOutTimeInMinutes
        clockInIdentityType
        clockOutIdentityType
        totalOvertimeInMinutes
        totalMissingTimeInMinutes
        firstClockIn {
          id
        }
        lastClockOut {
          id
        }
        clockInTerminal {
          name
        }
        clockInAccessTerminal {
          name
        }
        clockOutTerminal {
          name
        }
        clockOutAccessTerminal {
          name
        }
        shift {
          id
          name
          color
          beginTime
          endTime
          shiftType
          lengthInMinutes
          expectedWorkingTimeInMinutes
          totalWorkingTimeInMinutes
          totalBreakTimeInMinutes
          latelyClockInTolerance
          earlyClockOutTolerance
          maxClockOutOvertimeInMinutes
          maxClockInOvertimeInMinutes
        }
      }
    }
  }
`;

export const PENDING_WORKPLAN_REQUESTS = gql`
  query pendingRequests($id: ID!) {
    employee(id: $id) {
      pendingApprovalWorkplanRequests {
        id
        approver {
          id
          firstName
          lastName
          profile {
            imageUrl
          }
          employment {
            branch {
              name
            }
          }
        }
        workplanRequest {
          id
          requestDateTime
          workplanRequestStatus
          employee {
            id
            firstName
            lastName
            profile {
              imageUrl
            }
            employment {
              branch {
                name
              }
              unit {
                name
              }
              team {
                name
              }
              jobInfo {
                name
              }
            }
          }
          abolishReason
          abolishDateTime
          abolisher {
            id
            firstName
            lastName
            profile {
              imageUrl
            }
            employment {
              branch {
                name
              }
              unit {
                name
              }
              team {
                name
              }
              jobInfo {
                name
              }
            }
          }
          plannedWorkplans {
            countAsOvertime
            breakDay
            date
            workplan {
              leaveRequest {
                leaveType {
                  icon
                  code
                  color
                  name
                }
              }
              overtimes {
                requestStatus
                overtimeInMinutes
                period
              }
            }
            assignedShifts {
              code
              name
              color
              shiftType
              beginTime
              endTime
              lengthInMinutes
              totalBreakTimeInMinutes
              totalWorkingTimeInMinutes
              latelyClockInTolerance
              earlyClockOutTolerance
              maxClockInOvertimeInMinutes
              minClockInOvertimeInMinutes
              maxClockOutOvertimeInMinutes
              minClockOutOvertimeInMinutes
              totalBreakTimeInMinutesForWeb
              maxOvertimeInMinutes
              minOvertimeInMinutes
              midnightTolerance
            }
          }
          workplanRequestProcesses {
            id
            rank
            approved
            note
            eventDateTime
            nextApprover
            approver {
              id
              firstName
              lastName
              profile {
                imageUrl
              }
              employment {
                branch {
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const ALL_PENDING_WORKPLAN_REQUESTS = gql`
  query allPendingRequests($id: ID!) {
    employee(id: $id) {
      allPendingApprovalWorkplanRequests {
        id
        approver {
          id
          firstName
          lastName
          profile {
            imageUrl
          }
          employment {
            branch {
              name
            }
          }
        }
        workplanRequest {
          id
          requestDateTime
          workplanRequestStatus
          employee {
            id
            firstName
            lastName
            profile {
              imageUrl
            }
            employment {
              branch {
                name
              }
              unit {
                name
              }
              team {
                name
              }
              jobInfo {
                name
              }
            }
          }
          abolishReason
          abolishDateTime
          abolisher {
            id
            firstName
            lastName
            profile {
              imageUrl
            }
            employment {
              branch {
                name
              }
              unit {
                name
              }
              team {
                name
              }
              jobInfo {
                name
              }
            }
          }
          plannedWorkplans {
            countAsOvertime
            breakDay
            date
            workplan {
              leaveRequest {
                leaveType {
                  icon
                  code
                  color
                  name
                }
              }
              overtimes {
                requestStatus
                overtimeInMinutes
                period
              }
            }
            assignedShifts {
              code
              name
              color
              shiftType
              beginTime
              endTime
              lengthInMinutes
              totalBreakTimeInMinutes
              totalWorkingTimeInMinutes
              latelyClockInTolerance
              earlyClockOutTolerance
              maxClockInOvertimeInMinutes
              minClockInOvertimeInMinutes
              maxClockOutOvertimeInMinutes
              minClockOutOvertimeInMinutes
              totalBreakTimeInMinutesForWeb
              maxOvertimeInMinutes
              minOvertimeInMinutes
              midnightTolerance
            }
          }
          workplanRequestProcesses {
            id
            rank
            approved
            note
            eventDateTime
            nextApprover
            approver {
              id
              firstName
              lastName
              profile {
                imageUrl
              }
              employment {
                branch {
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;
